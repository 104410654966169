import React from "react"
import styles from "../RadioRow/RadioRow.module.scss"

interface Props {
  name: string
  title?: string
  value: any
  onChange: any
  checked: any
  error?: boolean
}

const CheckBoxRow: React.FC<Props> = ({ name, title, value, onChange, checked, error }) => {
  return (
    <label className={`${styles.radioRow}`}>
      <input type="checkbox" name={name} onChange={onChange} value={value} checked={checked} hidden />
      <div className={`${styles.radioRow__itemCheck} ${error ? `${styles.error} error` : ""}`}>
        <img src="/img/check.svg" alt="" />
      </div>
      <div className={styles.radioRow__itemText}>{title}</div>
    </label>
  )
}

export default CheckBoxRow
