import styles from './UploadBox.module.scss'
import React, {useRef} from 'react'
import {useDropzone} from "react-dropzone";
// import uploadFile from "@/helpers/upload/uploadFile";
// import {selectProjectUrl} from "@/redux/slices/ui";
import { useAppSelector } from '../../../hooks';
import { selectToken } from '../../../redux/slice/auth';
import { getApiUrl } from '../../../utils/getApiUrl';
import uploadFile from '../../../utils/upload/uploadFile';

interface Props {
  additionalClass?: string
  updatePhotoArr: any
  updateAttachmetsArr: any
  setFocusedInput: any
  scrollToBottomChat: any
  setIsLoading: any
}

const UploadBox: React.FC<Props> = ({
  additionalClass,
  updatePhotoArr,
  updateAttachmetsArr,
  setFocusedInput,
  scrollToBottomChat,
  setIsLoading,
}) => {
  const reader:any = new FileReader()
  const userToken = useAppSelector(selectToken)
  const siteUrl = getApiUrl()?.replace("/api/v1", "") ?? window?.location?.origin 

  const onDrop = (acceptedFiles: any) => {
    acceptedFiles.forEach((file: any) => {
      setIsLoading(true)
      reader.readAsDataURL(file)
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        uploadFile({
          file: reader.result,
          reader: file,
          updatePhotoArr,
          updateAttachmetsArr,
          setIsLoading,
          userToken,
          scrollToBottomChat,
          siteUrl,
        })
      }
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    onFileDialogOpen: () => {
      setFocusedInput(true)
    },
    onFileDialogCancel: () => {
      setFocusedInput(false)
    },
  })

  return (
    <div
      {...getRootProps({
        className: `${styles.box} ${additionalClass}`,
      })}
    >
      <div className={`${styles.box__icon}`}><img src="/img/clip.svg" alt="" /></div>
      <input type="file" {...getInputProps()} />
    </div>
  )
}

export default UploadBox
