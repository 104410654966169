import { PayloadAction, createSlice } from "@reduxjs/toolkit"

const initialState: any = {
  serviceList: [],
  serviceListLoading: true,
  countOrdersWithoutReview: 0,
  countUnreadMessages: 0,
  allMessage: [],
}

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    updateServiceList: (state: any, action: PayloadAction<any>) => {
      state.serviceList = [...action.payload.list]
      state.serviceListLoading = false
    },
    updateCountOrdersWithoutReview: (state: any, action: PayloadAction<any>) => {
      state.countOrdersWithoutReview = action?.payload?.data?.count
    },
    updateCountUnreadMessages: (state: any, action: PayloadAction<any>) => {
      state.countUnreadMessages = action?.payload?.data?.count
    },
    setAllMessage: (state: any, action: PayloadAction<any>) => {
      state.allMessage = action.payload
    },
    addAllMessage: (state: any, action: PayloadAction<any>) => {
      state.allMessage = [...state.allMessage.filter((f: any) => f.id !== action.payload.id), action.payload]
    },
    updateReadMessages: (state: any, action: PayloadAction<any>) => {      
      state.allMessage = [...state.allMessage.map((f: any) => action?.payload?.includes(f.id) ? {...f, is_readed: true} : f)]
    },
  },
})

export const {
  updateServiceList,
  updateCountOrdersWithoutReview,
  updateCountUnreadMessages,
  setAllMessage,
  addAllMessage, 
  updateReadMessages, } = mainSlice.actions
export const countOrdersWithoutReview = (state: any) => state[mainSlice.name].countOrdersWithoutReview
export const countUnreadMessages = (state: any) => state[mainSlice.name].countUnreadMessages
export const selectAllMessage = (state: any) => state[mainSlice.name].allMessage
