import React from 'react'
import { useDropzone } from 'react-dropzone'
import styles from "./OrderModal.module.scss"
import OrderModalBlock from './OrderModalBlock'

interface Props {
    el: any,
    setFieldValue: any
    values: any
    isRequired?: boolean
    error?: boolean
}

const OrderModalPhotoBlock:React.FC<Props> = ({el, setFieldValue, values, isRequired, error}) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps,
        inputRef,
    }: any = useDropzone({
        noClick: true,
        accept: {
            "image/jpeg": [],
            "image/png": [],
    },
    onDrop: (acceptedFiles) => {
        setFieldValue(`${inputRef.current.name}`, [
        ...values[inputRef.current.name],
        ...acceptedFiles.map((file: any) =>
            Object.assign(file, {
            preview: URL.createObjectURL(file),
            }),
        ),
        ])
    },
    })
    
    // Превьюшки
    const thumbs = (id: string) =>
        values[id]?.map((file: any) => (
            <div className={styles.photoBlock__preview} key={file.name}>
            <img
                src={file.preview}
                // Revoke data uri after image is loaded
                onLoad={() => {
                URL.revokeObjectURL(file.preview)
                }}
            />
            <button
                type="button"
                onClick={() =>
                setFieldValue(
                    id,
                    values[id].filter((el: any) => el.path !== file.path),
                )
                }
                className={styles.photoBlock__close}
            >
                <img src="/img/close.svg" alt="" />
            </button>
            </div>
        ))

    return (
        <OrderModalBlock title={`${el?.name}`} isRequired={isRequired}>
            <div className={`${styles.photoBlock}`}>
                {values[el.id]?.length ? thumbs(el.id) : null}
                <label {...getRootProps()} className={`${styles.photoBlock__append} ${error ? `${styles.error} error` : ""}`}>
                <input {...getInputProps()} hidden name={el.id} />
                <img className={styles.photoBlock__camera} src="/img/camera.svg" alt="append" />
                <div className={styles.photoBlock__plus}>
                    <img src="/img/plus.svg" alt="append" />
                </div>
                </label>
            </div>
        </OrderModalBlock>
    )
}

export default OrderModalPhotoBlock