import { api } from "./index"

export const contentApi = api.injectEndpoints({
  endpoints: (builder) => ({
    //создать заявку
    postCreateOrder: builder.mutation<any, FormData>({
      query: (data) => ({
        url: `public/service-orders/`,
        method: "POST",
        body: data,
      }),
    }),
    //получить список заявок
    postServiceOrders: builder.query<any, { limit?: number; offset?: number; isActive: boolean }>({
      query: (data) => ({
        url: `public/service-orders/getlist`,
        method: "POST",
        body: data,
      }),
      providesTags: ["Orders"],
    }),
    //получить информацию о заявке
    getServiceOrder: builder.query<any, { id: string }>({
      query: (data) => ({
        url: `public/service-orders/${data.id}`,
      }),
    }),
    //отменить заявку
    postCancelOrder: builder.mutation<any, { id: string; reason: string }>({
      query: (data) => ({
        url: `public/service-orders/cancel/${data.id}`,
        method: "POST",
        body: { reason: data.reason },
      }),
      invalidatesTags: ["Orders"],
    }),
    // отзывы
    getServiceOrderReviews: builder.mutation<any, { service_order_id: string }>({
      query: (data) => ({
        url: `public/service-orders/${data.service_order_id}/reviews`,
        method: "GET",
      }),
    }),
    getQuantityOrderWithoutReview: builder.mutation<any, void>({
      query: () => ({
        url: `public/service-orders/count-orders-without-reviews`,
        method: "GET",
      }),
    }),
    // Количество непрочитанных сообщений суммарно (на главной)
    getCountUnreadMessages: builder.mutation<any, void>({
      query: () => ({
        url: `public/service-orders/count-unread-massages`,
        method: "GET",
      }),
    }),
    postOrderReview: builder.mutation<any, any>({
      query: (data) => ({
        url: `public/service-orders/${data.id}/reviews`,
        method: "POST",
        body: {
          text : data.textReview,
          rating: data.ratingReview
        },
      }),
    }),
    postOrderModalWasOpened: builder.mutation<any, any>({
      query: (service_order_id) => ({
        url: `public/service-orders/${service_order_id}/modal-review-impression`,
        method: "POST",
      }),
    }),

    // Получить сообщения в чате
    getChatList: builder.mutation<any, { id: string }>({
      query: ({ id }) => ({
        url: `public/chat-messages/orders/${id}`,
        method: 'GET',
      }),
    }),
    // Отправить сообщение в чат
    sendChatMessage: builder.mutation<any, { id: string, data: any }>({
      query: ({ id, data }) => ({
        url: `public/chat-messages/create/orders/${id}`,
        method: 'POST',
        body: data,
      }),
    }),
    // Опметить сообщения как прочитанные
    markReadedMessage: builder.mutation<any, { data: any }>({
      query: ({ data }) => ({
        url: `public/chat-messages/mark-readed`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  usePostCreateOrderMutation,
  usePostServiceOrdersQuery,
  useLazyPostServiceOrdersQuery,
  useGetServiceOrderQuery,
  useLazyGetServiceOrderQuery,
  usePostCancelOrderMutation,
  useGetServiceOrderReviewsMutation,
  usePostOrderReviewMutation,
  useGetQuantityOrderWithoutReviewMutation,
  useGetCountUnreadMessagesMutation,
  usePostOrderModalWasOpenedMutation,
  useGetChatListMutation,
  useSendChatMessageMutation,
  useMarkReadedMessageMutation,
} = contentApi
