import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./header.module.scss"
import { useDispatch } from "react-redux"
import { authModalOpen } from "../../redux/slice/authModal"
import { useAppSelector } from "../../hooks"
import { selectAuth } from "../../redux/slice/auth"
import { Link } from "react-router-dom"
import { useGetCompanyLogoQuery } from "../../redux/api/auth"
import LocalizedLink from "../../hoc/LocalizedLink"
import {getApiUrl} from "../../utils/getApiUrl";
// import { useTranslation } from "next-i18next";

interface Props {
  isLoading?: any
}

const Header: React.FC<Props> = ({ isLoading }) => {
  // const [mobMenu, setMobMenu] = useState(false)
  // const userLogin = true
  // const { t, i18n } = useTranslation("common");
  const { t } = useTranslation("translation", { keyPrefix: `interface` })
  const dispatch = useDispatch()
  const user = useAppSelector(selectAuth)
  const { data: logoCompany } = useGetCompanyLogoQuery()

  const setOpenLogin = () => {
    dispatch(authModalOpen({ flag: true }))
  }

  return (
    <header className={styles.header}>
      <div className={styles.header__wp}>
        <div
          className={styles.header__auth}
          onClick={() => {
            if (!user.token) {
              setOpenLogin()
            }
          }}
        >
          <div className={`${styles.header__avatar} ${isLoading ? "selectBack" : ""}`}>
            {!isLoading && (
              <>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.99999 8.8C11.5464 8.8 12.8 7.5464 12.8 6C12.8 4.4536 11.5464 3.2 9.99999 3.2C8.4536 3.2 7.19999 4.4536 7.19999 6C7.19999 7.5464 8.4536 8.8 9.99999 8.8ZM9.99999 10C12.2091 10 14 8.20914 14 6C14 3.79086 12.2091 2 9.99999 2C7.79086 2 5.99999 3.79086 5.99999 6C5.99999 8.20914 7.79086 10 9.99999 10ZM6.48357 13.2967C5.63177 14.0991 5.09999 15.2374 5.09999 16.5C5.09999 16.8314 4.83137 17.1 4.49999 17.1C4.16862 17.1 3.89999 16.8314 3.89999 16.5C3.89999 13.4072 6.4072 10.9 9.49999 10.9H10.5C13.5928 10.9 16.1 13.4072 16.1 16.5C16.1 16.8314 15.8314 17.1 15.5 17.1C15.1686 17.1 14.9 16.8314 14.9 16.5C14.9 15.2374 14.3682 14.0991 13.5164 13.2967C13.1943 13.7898 12.7708 14.2117 12.2716 14.5327C11.6038 14.9621 10.8281 15.1934 10.0342 15.1999C9.24032 15.2063 8.46087 14.9877 7.78621 14.5692C7.26235 14.2443 6.81832 13.8092 6.48357 13.2967ZM7.46194 12.5995C7.70389 12.9834 8.03063 13.3087 8.41872 13.5494C8.90062 13.8483 9.45737 14.0045 10.0244 13.9999C10.5915 13.9953 11.1456 13.83 11.6226 13.5233C11.9929 13.2852 12.3049 12.9694 12.5381 12.5995C11.9287 12.2804 11.2354 12.1 10.5 12.1H9.49999C8.76455 12.1 8.07124 12.2804 7.46194 12.5995Z"
                    fill="#121318"
                  />
                </svg>
                {user?.user?.avatar_id ? (
                  <img src={getApiUrl() + "/web/user/avatar/" + user?.user?.avatar_id} alt="Avatar" />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          {!isLoading && <div className={styles.header__name}>{user?.user?.name ? user.user.name : t("login")}</div>}
        </div>
        {logoCompany && logoCompany?.data?.length !== 0 ? (
          <div className={styles.header__logo}>
            <LocalizedLink to={"/"}>
              <div className={styles.header__logo__container} dangerouslySetInnerHTML={{ __html: logoCompany }} />
            </LocalizedLink>
          </div>
        ) : null}
      </div>
    </header>
  )
}

export default Header
