import React, { useEffect, useState } from "react"
import styles from "./OrderItem.module.scss"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import {getApiUrl} from "../../utils/getApiUrl";

interface Props {
  img?: string
  title?: string
  subtitle?: string
  time?: string
  clickFcn?: any
  count: number
  layout?: string
  ratingFn?: () => void
  hasReview?: boolean
  orderId?: string
  status: number
  messageCounts: number | undefined
}

const OrderItem: React.FC<Props> = ({
  img,
  title,
  subtitle,
  time,
  clickFcn,
  count,
  layout,
  ratingFn,
  hasReview,
  orderId,
  status,
  messageCounts,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: `interface` })

  const [wasReview, setWasReview] = useState<boolean>(false)

  const { ordersList } = useSelector((state: any) => state.ordersList)

  useEffect(() => {
    const order = ordersList.find((el: any) => el.id === orderId)
    if (order) {
      setWasReview(order.hasReview)
    }
  })

  return (
    <button type="button" className={styles.orderItem} onClick={clickFcn}>
      <div className={styles.orderItem__img}>
        <img src={img ? getApiUrl() + "/public/photo/preview/" + img : "/img/productNoImg.svg"} alt="" />
        {!!messageCounts && <span className={`${styles.ordersLine__ordersNoReview}`}>{messageCounts}</span>}
      </div>
      <div className={styles.orderItem__info}>
        <p>
          {title} {count > 1 ? <span>x{count}</span> : null}
        </p>
        <span>{subtitle}</span>
        {status === -1 && <div className={styles.orderItem__canceled}>{t('canceledOrder')}</div>}
      </div>
      <div className={styles.orderItem__time}>{time}</div>
      {layout !== "active" && ratingFn && !wasReview && status === 1 && (
        <button
          className={styles.orderItem__ratingBtn}
          onClick={(e) => {
            e.stopPropagation()
            ratingFn()
          }}
        >
          {t("giveRating")}
        </button>
      )}
    </button>
  )
}

export default OrderItem
