import React from 'react'
import { getApiUrl } from '../../../../utils/getApiUrl'
import { getFileIco } from '../../../../utils/helpers'
import styles from "./styles.module.scss"


interface Props {
    el:any,
    iter?:any,
    fileDelete?: any
    isAction?: boolean
}

const AttachmentItemChat:React.FC<Props> = ({el, iter, fileDelete, isAction}) => {
    const fileType = el?.filename?.substring(el?.filename?.lastIndexOf('.') + 1, el?.filename?.length)
    const [iconVisible, iconSrc] = getFileIco(fileType)
    return (
        <a 
            href={`${getApiUrl()}/public/attachment/${el?.id}`} 
            className={`${styles.orderModal__file}`} 
            target="_blank" 
            rel="noreferrer" 
            key={el?.id || iter} 
            onClick={(e)=>{
                if (isAction) e.preventDefault()
            }}
        >
            {iconVisible ? (
                <img src={`${iconSrc}` ?? ''} alt=""/>) 
                : (<></>)
            }
            <div className={styles.orderModal__fileInfo}>
                <div className={styles.orderModal__fileName}>
                    {el.filename}
                </div>
                <div className={styles.orderModal__fileSize}>
                    {`${Number(el?.size / 1000000).toFixed(2)}Mb`}
                </div>
            </div>
            {isAction && 
                <button
                    type="button"
                    className={`${styles.orderModal__fileDelete}`}
                    onClick={() => {
                        fileDelete(el.id)
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <circle cx="7.99967" cy="7.99992" r="6.66667" stroke="#ABB8C8" strokeWidth="1.4"/>
                        <path d="M5 8H11" stroke="#ABB8C8" strokeWidth="1.4" strokeLinecap="round"/>
                    </svg>
                </button>
            }
        </a>
    )
}

export default AttachmentItemChat