import React from "react"
import styles from "./RadioRow.module.scss"

interface Props {
  name: string
  title?: string
  value: any
  onChange?: any
  checked: any
  onClick?: any
  withChildrens?: boolean
  withChildrensFcn?: any
}

const RadioRow: React.FC<Props> = ({ name, title, value, onChange, checked, onClick, withChildrens, withChildrensFcn }) => {
  return (
    <label className={`${styles.radioRow}`} onClick={(e:any)=>{
      if (withChildrens) {
        e.preventDefault()
        withChildrensFcn()
      }   
    }}>
      <input type="radio" name={name} onChange={withChildrens ? ()=>null : onChange} value={value} readOnly checked={checked} onClick={onClick} hidden />
      <div className={styles.radioRow__itemIco} />
      <div className={styles.radioRow__itemText}>{title}</div>
    </label>
  )
}

export default RadioRow
