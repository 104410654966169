import React from "react"
import styles from "./OrderModal.module.scss"

interface Props {
  title?: string | number
  children: any
  isRequired?: boolean
  error?: boolean
}

const OrderModalBlock: React.FC<Props> = ({ title, children, isRequired, error }) => {
  return (
    <div className={styles.orderModal__block}>
      {!!title && <div className={`${styles.orderModal__blockTitle} ${(error && isRequired) ? `${styles.orderModal__blockRequired} error` : ""}`}>{title} {isRequired && <span className={styles.orderModal__blockRequired}>*</span>}</div>}
      {children}
    </div>
  )
}

export default OrderModalBlock
