import React, {useEffect, useRef, useState} from 'react'
import styles from "./ChatModal.module.scss"
import Modal from '../../Modal/Modal'
import TitleBack from '../../TitleBack/TitleBack'
import ReactTextareaAutosize from "react-textarea-autosize"
import { useTranslation } from 'react-i18next'
import ChatMessage from './ChatMessage'
import moment from 'moment'
import FileDropzone from '../../Assets/FileDropzone/FileDropzone'
import { useFormik } from 'formik'
import {useDispatch} from "react-redux";
import {addAllMessage, selectAllMessage, updateCountUnreadMessages, updateReadMessages} from "../../../redux/slice/main";
import { v4 as uuidv4 } from 'uuid'
import {useAppSelector} from "../../../hooks";
import {selectToken, selectUser} from "../../../redux/slice/auth";
import {useGetCountUnreadMessagesMutation, useMarkReadedMessageMutation, useSendChatMessageMutation} from "../../../redux/api/content";
import {selectOpenOrder, setNeedClearNewMessageCount} from "../../../redux/slice/ordersList";
import ChatDay from "./ChatDay";
import { getApiUrl } from '../../../utils/getApiUrl'
import UploadBox from '../../Assets/UploadBox/UploadBox'
import AttachmentItemChat from './assets/AttachmentItemChat'
import uploadFile from '../../../utils/upload/uploadFile'

interface Props {
    openChat: boolean
    setOpenChat: any
    orderInfo: any
    isFirstLoadMess: boolean
}

const ChatModal:React.FC<Props> = ({openChat, setOpenChat, orderInfo, isFirstLoadMess}) => {
    const { t } = useTranslation("translation", { keyPrefix: `interface` })
    const dispatch = useDispatch()
    const [message, setMessage] = useState('')
    const [photoArr, setPhotoArr] = useState<any[]>([])
    const [attachmetsArr, setAttachmetsArr] = useState<any[]>([])
    const [focusedInput, setFocusedInput] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const nowUser = useAppSelector(selectUser)
    const openOrder = useAppSelector(selectOpenOrder)
    const allMessage = useAppSelector(selectAllMessage)
    const [sendChatMessage] = useSendChatMessageMutation()
    const [markReadedMessage] = useMarkReadedMessageMutation()
    const [getCountUnreadMessages] = useGetCountUnreadMessagesMutation()
    const scrollbarRef = useRef<any>()
    const isFirstScroll = useRef<any>(false)
    const isFirstIdNewMess = useRef<any>(false)
    const idNewMess = useRef<any>(false)

    const userToken = useAppSelector(selectToken)
    const siteUrl = getApiUrl()?.replace("/api/v1", "") ?? window?.location?.origin 

    const updatePhotoArr = (data: any) => {
        setPhotoArr((prev) => [...prev.filter((f: any) => f.frontId !== data.frontId), data])
    }

    const updateAttachmetsArr = (data: any) => {        
        setAttachmetsArr((prev) => [...prev.filter((f: any) => f.frontId !== data.frontId), data])
    }
    
    const removePhoto = (id: string) => {
        setPhotoArr((prev) => [...prev.filter((f: any) => f.id !== id)])
    }

    const removeFile = (id: string) => {
        setAttachmetsArr([...attachmetsArr.filter((f: any) => f.id !== id)])
    }

    const elementIsViewport = (element: string) => {
        const nowScrollbar = scrollbarRef?.current

        if (nowScrollbar) {
            const isViewTop =
              nowScrollbar.querySelector(element)?.getBoundingClientRect()?.top - nowScrollbar.getBoundingClientRect().top
            const isViewBottom =
              nowScrollbar.querySelector(element)?.getBoundingClientRect().top -
              nowScrollbar.getBoundingClientRect().top +
              nowScrollbar.querySelector(element)?.clientHeight
            return (
              (isViewTop > 0 && isViewTop < nowScrollbar.clientHeight) ||
              (isViewBottom > 0 && isViewBottom < nowScrollbar.clientHeight)
            )
        } else {
            return false
        }
    }

    useEffect(() => {
        if (typeof window.Echo !== 'undefined' && openOrder?.order_id) {
            const channel = window.Echo.join(`orders.${openOrder?.order_id}`)

            channel.listen('.client-addMessage', (event: any) => {
                dispatch(addAllMessage(event))
            })
        }
    }, [openChat, openOrder])

    useEffect(() => {
        if (allMessage.length > 0) {
            const nowScrollbar = scrollbarRef?.current

            if (
              isFirstLoadMess ||
              scrollbarRef?.current?.scrollHeight -
              scrollbarRef?.current?.scrollTop -
              nowScrollbar?.current?.clientHeight ===
              0 ||
              elementIsViewport('.chatMessage:last-child') ||
              elementIsViewport('.chatMessage:nth-last-child(-n+2)')
            ) {
                if (
                  isFirstLoadMess &&
                  isFirstScroll.current === false &&
                  scrollbarRef?.current?.querySelector('.chatNewMess')
                ) {
                    scrollbarRef?.current?.scrollTop(
                      nowScrollbar.querySelector('.chatNewMess')?.getBoundingClientRect()?.top -
                      nowScrollbar.getBoundingClientRect().top
                    )
                    isFirstScroll.current = true
                } else {
                    scrollbarRef?.current?.scrollTo(0, scrollbarRef?.current.scrollHeight);
                }
            }
        }
        const removeIds = [...allMessage.filter((el: any) => !el.is_readed).map((el: any) => el.id)]

        if (removeIds.length > 0) {
            setTimeout(() => {
                markReadedMessage({
                    data: {
                        ids: removeIds,
                    },
                }).then(() => {
                    // Обновление количества заявок для главной
                    getCountUnreadMessages().then((res: any) => {
                        dispatch(updateCountUnreadMessages(res))
                    })
                })
                // Установка прочитанных сообщений
                dispatch(updateReadMessages(removeIds))
                dispatch(setNeedClearNewMessageCount(String(orderInfo?.id)))
            }, 400)
        }
    }, [allMessage])   

    const scrollToBottomChat = () => {
        if (
          scrollbarRef?.current?.scrollHeight -
          scrollbarRef?.current?.scrollTop -
          scrollbarRef?.current?.clientHeight ===
          0
        ) {
            setTimeout(() => {
                scrollbarRef?.current?.scrollTo(0, scrollbarRef?.current.scrollHeight);
            }, 350)
        }
    }

    const pastData = (event: any) => {
        const items = (event.clipboardData || event.originalEvent.clipboardData).items
        let blob: any = null

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') === 0) {
                blob = items[i].getAsFile()
            }
        }

        if (blob !== null) {
            const reader = new FileReader()
            reader.onload = function (event) {
                //  todo Добавить загрузчик файлов, урлы те же, что и на админке, только тут отсутсвует /web в урле 
                 uploadFile({
                    file: reader.result,
                    reader: blob,
                    updatePhotoArr,
                    updateAttachmetsArr,
                    setIsLoading,
                    userToken,
                    scrollToBottomChat,
                    siteUrl,
                })
            }
            reader.readAsDataURL(blob)
        }
    }

    const sendMessage = () => {
        if (message.trim().length > 0 || photoArr.length > 0 || attachmetsArr.length > 0) {
            const idMess = uuidv4()

            const messageData = {
                id: idMess,
                user: {
                    id: nowUser.id,
                    name: nowUser.name,
                    avatar_id: nowUser.avatar_id,
                },
                text: message,
                photos: [...photoArr.map((el: any) => el.id)],
                photos_info: [
                    ...photoArr.map((el: any) => {
                        return {
                            createdAt: el.createdAt,
                            filename: el.filename,
                            id: el.id,
                            previewId: el.id,
                        }
                    }),
                ],
                attachments: [...attachmetsArr.map((el: any) => el.id)],
                attachments_info: [
                    ...attachmetsArr.map((el: any) => {
                        return {
                            id: el.id,
                            filename: el.filename,
                            size: el.size,
                            createdAt: el.createdAt,
                        }
                    }),
                ],
                created_at: new Date().valueOf() / 1000,
                is_readed: false,
                readed_at: null,
            }

            setMessage('')
            setAttachmetsArr([])
            setPhotoArr([])

            dispatch(addAllMessage(messageData))

            window.Echo?.connector?.pusher?.channel(`presence-orders.${openOrder?.order_id}`)?.trigger('client-addMessage', messageData)

            sendChatMessage({
                id: openOrder?.order_id,
                data: {
                    id: idMess,
                    text: message,
                    attachments: [...attachmetsArr.map((el: any) => el.id)],
                    photos: [...photoArr.map((el: any) => el.id)],
                },
            })
        }
    }

    const formik:any = useFormik({
        initialValues: {
            text: "",
            attachments: [],
            attachments_info: [],
        },
        onSubmit(values, formikHelpers) {
            sendMessage()
        },
    })
    return (
        <div className={styles.chatModal__wp}>
            <Modal open={openChat} setOpen={setOpenChat}>
                <div className={styles.chatModal}>
                    <div className={styles.chatModal__head}>
                        <TitleBack title={orderInfo?.name} onClick={()=>setOpenChat(false)} noLinkBack/>
                    </div>
                    <div className={`${styles.chatModal__main}`} ref={scrollbarRef}>
                        
                        <div className={`${styles.chatModal__mainListWp}`}>
                            <div className={styles.chatModal__mainBack} style={{
                                backgroundImage: "url(/img/chat_bg.png)"
                            }} />
                        
                        <div className={styles.chatModal__mainList}>
                        
                            {allMessage.map((el: any, kk: number) => {
                                if (
                                  kk !== 0 &&
                                  idNewMess.current === false &&
                                  isFirstIdNewMess.current !== true &&
                                  el?.is_readed === false
                                ) {
                                    idNewMess.current = el.id
                                    isFirstIdNewMess.current = false
                                }

                                return (
                                    <React.Fragment key={el.id}>
                                        {idNewMess.current === el.id && (
                                          <div className={styles.chatModal__newMessage}>
                                              <span>{t("newMessage")}</span>
                                          </div>
                                        )}
                                        <ChatDay prevEl={allMessage[kk - 1] || false} nowEl={el} />
                                        <ChatMessage
                                            message={el}
                                            nowUserId={nowUser?.id}
                                        />
                                    </React.Fragment>
                                )
                            })}
                        </div>
                        </div>
                    </div>
                    <div className={styles.chatModal__bottom}>
                        {/* <label className={styles.chatModal__append}>
                            <img src="/img/clip.svg" alt="" />
                            <FileDropzone name={"attachments"} setFieldValue={formik.setFieldValue} values={formik.values} />
                        </label> */}
                        <UploadBox 
                            updatePhotoArr={updatePhotoArr}
                            updateAttachmetsArr={updateAttachmetsArr}
                            setFocusedInput={setFocusedInput}
                            scrollToBottomChat={scrollToBottomChat}
                            setIsLoading={setIsLoading}
                        />
                        <div className={styles.chatModal__inputWp}>
                            <ReactTextareaAutosize
                                minRows={1}
                                maxRows={3}
                                placeholder={t("enterMessage")}
                                value={message}
                                className={styles.chatModal__input}
                                onFocus={() => {
                                    scrollToBottomChat()
                                }}
                                onChange={(ev: any) => {
                                    setMessage(ev.target.value)
                                }}
                                onKeyDown={(ev: any) => {
                                    if (ev.keyCode === 13 && !ev.shiftKey) {
                                        ev.preventDefault()
                                        sendMessage()
                                    }
                                }}
                                onPaste={(ev: any) => {
                                    pastData(ev)
                                }}
                            />
                        <div className={styles.chatUpload}>
                            {/* console.log(her) */}
                            
                            {photoArr.length > 0 ? (
                                <div className={styles.chatUpload__photos}>
                                {photoArr.map((el: any, kk) => {
                                    return (
                                    <div className={styles.chatUpload__photo} key={el.id + Math.random() * 1000}>
                                        <img
                                            src={`${el.preview ? el.preview : `${getApiUrl()}${process.env.NEXT_PUBLIC_API_SUFFIX}/photo/${el.id}`}`}
                                            alt={el.filename}
                                            />
                                        {el.progress && el.progress !== 100 && (
                                        <div className={styles.chatUpload__photoProgress}>
                                            {/* <CircleProgress progress={el.progress} /> */}
                                            <span>{el.progress}%</span>
                                        </div>
                                        )}
                                        <div
                                            className={styles.chatUpload__removeImage}
                                            onClick={() => {
                                                removePhoto(el.id)
                                            }}
                                            >
                                                <img src="/img/close.svg" alt="" />
                                        </div>
                                    </div>
                                    )
                                })}
                                </div>
                            ) : null}
                            {attachmetsArr.length > 0 ? (
                                <div className={styles.chatUpload__attachments}>
                                {attachmetsArr.map((el: any, kk) => {
                                    return (
                                    <AttachmentItemChat
                                        key={el.id + Math.random() * 1000}
                                        el={el}
                                        iter={kk}
                                        fileDelete={removeFile}
                                        isAction={true}
                                    />
                                    )
                                })}
                                </div>
                            ) : null}
                            </div>
                        </div>
                        <button className={styles.chatModal__submit} onClick={() => sendMessage()}>
                            <img src="/img/send.svg" alt="" />
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default ChatModal