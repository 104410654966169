import styles from './ChatModal.module.scss'
import {useTranslation} from "react-i18next";
import moment from "moment";
import React from "react";

interface Props {
	prevEl: any
	nowEl: any
}

const ChatDay: React.FC<Props> = ({ prevEl, nowEl }) => {
	const { t } = useTranslation("translation", { keyPrefix: `interface` })

	const nowElDay = moment(nowEl.created_at * 1000).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
	const prevElDay = moment(prevEl.created_at * 1000).set({ hour: 0, minute: 0, second: 0, millisecond: 0 })

	if (!prevEl) {
		return (
			<div className={styles.chatModal__chatDate}>{nowElDay.format('DD MMMM YYYY')}</div>
		)
	} else if (!moment(prevElDay).isSame(nowElDay, 'day')) {
		let dateText = nowElDay.format('DD MMMM YYYY')
		if (moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).isSame(nowElDay, 'days')) {
			dateText = t('today')
		} else if (moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).diff(nowElDay, 'days') === 1) {
			dateText = t('yesterday')
		}
		return (
			<div className={styles.chatModal__chatDate}>{dateText}</div>
		)
	}

	return null
}

export default ChatDay
