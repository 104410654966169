import React from 'react'
import styles from "./ChatModal.module.scss"
import moment from 'moment'
import { getFileIco } from '../../../utils/helpers'
import {getApiUrl} from "../../../utils/getApiUrl";
import AttachmentItemChat from './assets/AttachmentItemChat';
import { GetUserAvatar } from '../../../utils/getUserAvatar';
import {urlify} from "../../../utils/urlify";

interface Props {
    message: any
    nowUserId: string
}

const ChatMessage:React.FC<Props> = ({message, nowUserId}) => {
  const itsMe = message.user.id === nowUserId
    return (
        <div className={`${styles.chatMessage} chatMessage ${itsMe ? styles.chatMessage_my : ""} `}>
            {!itsMe &&
                <div className={`${styles.chatMessage__avatar}`}>
                    <GetUserAvatar name={message?.user?.name} avatar={message?.user?.avatar_id || false} />
                    {/* <img src={`${message.user.avatar_id ? getApiUrl()+'/web/user/avatar/'+message.user.avatar_id : ''}`} alt="" /> */}
                </div>
            }
            <div className={`${styles.chatMessage__block}`}>
                {!itsMe && <div className={`${styles.chatMessage__name}`}>{message.user.name}</div>}
                <div className={`${styles.chatMessage__text}`} dangerouslySetInnerHTML={{ __html: urlify(message.text) }} />
                {/* Изображения */}
                {!!message.photos?.length && 
                    <div className={`${styles.chatMessage__images}`}>{message.photos?.map((el:string, kk:number)=><img key={kk} src={getApiUrl()+'/public/photo/' + el} alt='' />)}</div>
                }
                {/* Файлы */}
                {/* {!!message.photos?.length &&  */}
                    <div className={`${styles.chatMessage__files}`}>
                        {message.attachments_info?.map((el:any, kk:number)=>{
                            return <AttachmentItemChat 
                                el={el}
                                key={kk}
                            />
                        })}
                    </div>
                {/* } */}
                <div className={`${styles.chatMessage__time}`}>{moment(message.created_at * 1000).format("HH:mm")}</div>
            </div>
        </div>
    )
}

export default ChatMessage